@import './styles/base';

%read-only {
  user-select: none;
  background: #f8f9fc;
  border-radius: 6px;
  cursor: not-allowed;
  border-color: #f8f9fc;
  pointer-events: none;
}

.content {
  flex: 1;
  height: 100%;
  padding: 32px;
  min-width: 320px;

  :global(.for-screen) {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  form {
    width: 100%;
    max-width: 600px;
    padding-bottom: 32px;
    border-bottom: 1px solid #dfe1e5;
    margin-bottom: 32px;

    h1 {
      font: 600 24px/34px $font-family;
      width: 100%;
      margin-bottom: 24px;
    }

    h2 {
      font: 600 20px/32px $font-family;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      font: 600 16px/28px $font-family;
      margin-bottom: 12px;
      padding-top: 16px;
    }
  }
}

.switch {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .trigger {
    margin-left: 8px;
  }
}

fieldset {
  display: flex;
  justify-content: space-between;

  > .input-box {
    flex: 1 1 calc((100% - 16px) / 2);

    &:first-child {
      margin-right: 16px;
    }
  }
}

.input-box {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: calc((100% - 16px) / 2);

  label {
    white-space: nowrap;
    display: block;
    font-size: 13px;
    line-height: 13px;
    color: $text-light-color;
    margin-bottom: 8px;

    span {
      color: $placeholder-color;
    }
  }

  .input {
    position: relative;

    &.percentage {
      &::after {
        pointer-events: none;
        content: '';
        width: 14px;
        height: 21px;
        background: url('./assets/icon-percent.svg') center no-repeat;
        background-size: contain;
        right: 16px;
        @extend %vertical-center;
      }
    }
  }

  input[type='number'] {
    display: block;
    width: 100%;
    height: 44px;
    font: bold 14px/48px $font-family;
    padding: 0 16px;
    color: #333;
    letter-spacing: 0.02em;
    caret-color: $blue;
    border: 1px solid #dfe1e5;

    &:focus {
      border-color: $highlight;
    }

    &::placeholder {
      color: $placeholder-color;
    }

    &:read-only {
      @extend %read-only;
    }

    &:focus-visible {
      outline: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  padding: 23px 109px;
  background-color: $color-background-gray;
  border-radius: 6px;

  .label {
    font-size: 15px;
    line-height: 22px;
    color: #6b748d;
  }

  .uplift {
    color: $text-color;
    font: bold 40px/1.6 $font-family;
    letter-spacing: 0.03em;

    span {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .content {
    form {
      width: 100%;
      max-width: 100%;

      h2 {
        font-size: 18px;
        flex-direction: column;
        margin-bottom: 16px;

        .switch {
          width: 100%;
          justify-content: flex-end;
          margin: 4px 0;
        }
      }
    }
  }

  fieldset {
    display: block;

    > .input-box {
      display: block;
      width: 100%;
      max-width: none;

      &:first-child {
        margin-right: 0;
        margin-bottom: 16px;
      }

      label {
        white-space: normal;
        line-height: 1.2;
      }
    }
  }

  .result {
    padding: 24px;
  }
}
