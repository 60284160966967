@import '../styles/base';

.header {
  height: 70px;
  background: $color-black-berry;

  header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-column-gap: 0px;
    align-items: start;
    padding: 20px 30px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    > * {
      white-space: nowrap;
    }

    .logo {
      padding-right: 8px;
      justify-self: start;
    }
  }
}
