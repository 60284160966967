$font-family: 'A-OTF UD Shin Go Pr6N', a-otf-ud-shin-go-pr6n, -apple-system,
  BlinkMacSystemFont, 'Hiragino Sans', Osaka, sans-serif;
$font-family-mono: 'Montserrat', 'Helvetica Neue', 'SF Mono', monospace;

$font-weight: (
  thin: 100,
  extra-light: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900,
);
