@import './styles/base';

.main {
  width: 100%;
  color: #000;

  > * {
    width: 100%;
  }

  > h1 {
    text-align: center;
    font: bold 28px/1.8 $font-family;
    margin-top: 16px;

    + article {
      padding-top: 16px;
    }
  }

  .merchant-name {
    text-align: center;
    font: 600 18px/1.6 $font-family;
    margin: 8px 0 0;

    + article {
      padding-top: 16px;
    }
  }

  article {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    h2 {
      font: bold 22px/1.6 $font-family;
      padding: 8px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(#ccc, 0.7);
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label,
        .value {
          line-height: 36px;
        }

        .label {
          font-size: 18px;
        }

        .value {
          font-size: 20px;
          font-family: $font-family;
          letter-spacing: -0.02em;

          &.hightlight {
            font-weight: bold;
            border-bottom: 3px double #000;
            font-size: 22px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
