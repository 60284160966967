@import './styles/base';
@import './styles/reset';

$_input-height: 40px;

html,
body,
#root {
  height: 100%;
  background: #fff;
  overflow: hidden;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c2e57;
}

* {
  box-sizing: border-box;
}

img {
  object-fit: contain;
  display: block;
}

a {
  text-decoration: none;
  color: #fff;
}

fieldset {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

label {
  white-space: nowrap;
  display: block;
  font-size: 13px;
  line-height: 13px;
  color: #6b748d;
  margin-bottom: 8px;
}

input[type='number'] {
  border: 1px solid #dfe1e5;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font: 400 14px/48px $font-family;
  padding: 0 16px;
  color: #333;
  letter-spacing: 0.02em;
  caret-color: $blue;
  appearance: none;
  -moz-appearance: textfield;
}

::placeholder {
  color: #c7c8cc;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(#999, 0.5);
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.for-print {
  display: none;
}

@include respond-to(mobile) {
  #root {
    overflow: auto;
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 10mm;
  }

  .for-print {
    display: block !important;
  }

  .for-screen {
    display: none !important;
  }
}
