$color-black-berry: #231c45;
$color-black-berry-light: #3a3262;
$color-background-gray: #f8f9fc;
$blue: #2461a8;
$text-color: #2c2e57;
$text-light-color: #6b748d;
$highlight: #5265f7;
$alert-color: #d4388a;
$placeholder-color: #c7c8cc;
$success-color: #27885f;
$success-light-color: #ddf6e2;
