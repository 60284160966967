@import '../styles/base';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-wrapper {
  .header {
    height: 128px;
    background: $color-black-berry;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.main-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }
}

@media print {
  @page {
    size: A4 landscape;
    margin: 10mm;
  }

  .header-wrapper {
    display: none;
  }
}
